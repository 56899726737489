import { TranslateApplicationService } from '../../../services/translate/translate-application.service';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { MfeAttribute, MfeEvent, MfeFileSource } from '@rappi/rappi-mfe-tools/lib';
import { MfeSourceLoaderService } from '../../../mfe/services';
import { EncryptService } from '../../../services/encrypt/encrypt.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppState } from '../../../store/states/app.state';
import { environment } from '../../../../environments/environment';
import { Store } from '@ngrx/store';
import { MicroFrontendWrapperComponent } from '../../../mfe/models/abstracts';

@Component({
  selector: 'app-mtz-sampling-mgmt-mfe',
  templateUrl: '../../../mfe/models/abstracts/MicroFrontendWrapper.html',
  styleUrls: ['../../../mfe/models/abstracts/MicroFrontendWrapper.scss']
})
export class MtzSamplingMgmtMfeComponent extends MicroFrontendWrapperComponent implements OnInit, OnDestroy {
  protected elName: string;
  protected mfeSources: MfeFileSource[];
  protected mfeAttributes: MfeAttribute[];
  protected mfeEvents: MfeEvent[];

  constructor(
    public readonly sourceLoader: MfeSourceLoaderService,
    public readonly cd: ChangeDetectorRef,
    public readonly encryptService: EncryptService,
    public readonly router: Router,
    public readonly activatedRoute: ActivatedRoute,
    public readonly store: Store<AppState>,
    public readonly translateApplicationService: TranslateApplicationService
  ) {
    super(sourceLoader, cd, encryptService, router, activatedRoute, store, translateApplicationService);
    this.elName = 'mtz-sampling-mgmt-mfe';
    this.url = environment.urlMtzSamplingMgmtMfeSourceRoot;
  }
}

import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl, ValidationErrors, FormControl } from '@angular/forms';

import { AuthService } from '../../../services/auth/auth.service';

import { matchFields } from './custom-validators';
import { PasswordValidatorConst, UserSuccessActionResponse } from '../../../models/user/user.model';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertsService } from '../../../services/alerts/alerts.service';
import { AlertsType } from '@rappi/ui/alerts';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm: FormGroup;
  token: string;

  readonly PASSWORD_MATCH_ERROR_MESSAGE = `Password and Confirm Password fields doesn't match`;
  readonly ERRORS = ['required', 'pattern'];
  readonly ERRORS_MESSAGE = {
    required: 'This field is required',
    pattern: PasswordValidatorConst.regexErrorMessage
  };

  constructor(
    private readonly _formBuilder: FormBuilder,
    private readonly _route: ActivatedRoute,
    private readonly _authService: AuthService,
    private readonly _alertsService: AlertsService,
    private readonly _router: Router
  ) {}

  errorsMapper = (error: string): string => this.ERRORS_MESSAGE[error];

  ngOnInit() {
    this.buildForm();
    this.token = this._route.snapshot.params['token'];
  }

  buildForm() {
    this.changePasswordForm = this._formBuilder.group(
      {
        password: ['', Validators.compose([Validators.required, Validators.pattern(PasswordValidatorConst.regex)])],
        confirmPassword: ['', Validators.required]
      },
      { validators: matchFields.bind(this, 'password', 'confirmPassword') }
    );
  }

  get password(): AbstractControl {
    return this.changePasswordForm.get('password');
  }

  get confirmPassword(): AbstractControl {
    return this.changePasswordForm.get('confirmPassword');
  }

  onSubmit(f: FormGroup) {
    if (!f.valid) {
      return;
    }

    this._authService.reset(f.controls.password.value, this.token).subscribe((response: UserSuccessActionResponse) => {
      this._alertsService.openAlerts(response.message, AlertsType.success);
      this._router.navigate(['/login']);
    });
  }

  customErrorsMapper(formControlErrors: ValidationErrors): string[] {
    const errors: string[] = Object.keys(formControlErrors || {});

    return errors.map((err: string) => {
      let message: string;

      switch (err) {
        case 'required':
          message = 'This field is required';
          break;
        case 'pattern':
          message = `Invalid password, must be at least 8 character long and contains one uppercase letter,
            one lowercase letter and one number`;
          break;
        case 'match':
          message = `This field doesn't match with ${formControlErrors[err].matchName} field`;
          break;
        default:
          message = 'This field is invalid';
          break;
      }

      return message;
    });
  }
}

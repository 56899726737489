import { Component, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { GenericDict } from '@rappi/common';
import {
  DynamicTableCellType,
  DynamicTableComponent,
  DynamicTableData,
  DynamicTableRow,
  LazyTableDataSource
} from '@rappi/ui/dynamic-table';
import { Observable, Subject } from 'rxjs';
import { map, skip, takeUntil, tap } from 'rxjs/operators';
import { TableMenuTriggerComponent } from '../../../../components/table-menu-trigger/table-menu-trigger.component';
import { Paginate, Permission } from '../../../../definitions/permissions.models';
import {
  AccountStatus,
  AccountStatusColor,
  AccountStatusMessage,
  UserType
} from '../../../../definitions/permissions.enums';
import { AccountList } from '../../../../definitions/accounts.models';
import { AccountsService } from '../../../../services/accounts.service';
import { AppState } from '../../../../../store/states/app.state';
import { UserState } from '../../../../../store/states/user.state';
import { StoreName } from '../../../../../store/definitions/store.constants';
import { MakerListComponent } from '../maker-list/maker-list.component';

import * as AccountActions from '../../../../store/actions/account.actions';

import { ConfirmAccountComponent } from '../../../../../core/landing/confirm-account/confirm-account.component';
import { UtilService } from '../../../../services/util.service';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit, OnDestroy {
  @ViewChild('table') table: DynamicTableComponent;
  @ViewChild('makerTemplateRef') makerTemplateRef: TemplateRef<ElementRef>;
  @ViewChild('expiration') expirationTemplate: TemplateRef<ElementRef>;

  accountsTableData: DynamicTableData;
  destroySubject$ = new Subject<void>();

  readonly ACCOUNT_STATUS = AccountStatus;
  readonly ACCOUNT_STATUS_COLOR = AccountStatusColor;
  readonly ACCOUNT_STATUS_MESSAGE = AccountStatusMessage;

  private readonly PAGE_SIZE = 5;
  private readonly INIT_PAGE = 1;

  constructor(
    private readonly _store: Store<AppState>,
    private readonly _accountService: AccountsService,
    private readonly _dialog: MatDialog,
    private readonly _utilService: UtilService,
  ) {}

  ngOnInit() {
    this.accountsTableData = this.initTableConfig();

    this._store.pipe(select(StoreName.country), skip(1), takeUntil(this.destroySubject$)).subscribe(() => {
      this.table.resetTable();
    });
  }

  initTableConfig(): DynamicTableData {
    const dataSource = new LazyTableDataSource<DynamicTableRow>(
      this.getAccountList.bind(this),
      this.PAGE_SIZE,
      '',
      this.INIT_PAGE
    );

    return {
      title: {
        value: 'ACCOUNTS MANAGEMENT'
      },
      dataSource,
      lazyLoaded: true,
      pagination: true,
      message: {
        title: 'No data'
      },
      paginatorOpts: { pageSize: this.PAGE_SIZE },
      columns: ['title','company_id', 'email', 'tier', 'licenses', 'maker', 'expiration', 'actions'],
      cellsConfig: {
        title: {
          type: DynamicTableCellType.text,
          title: 'Account Title'
        },
        company_id: {
          type: DynamicTableCellType.text,
          title: 'Company ID'
        },
        email: {
          type: DynamicTableCellType.text,
          title: 'Email Address'
        },
        tier: {
          type: DynamicTableCellType.text
        },
        licenses: {
          type: DynamicTableCellType.text
        },
        maker: {
          type: DynamicTableCellType.templateRef
        },
        expiration: {
          type: DynamicTableCellType.templateRef
        },
        actions: {
          type: DynamicTableCellType.customComponent,
          properties: {
            component: TableMenuTriggerComponent,
            inputs: ['id', 'type', 'service', 'isAdmin$', 'action', 'confirmAccount', 'sendEmail', 'resetPassword'],
            outputs: []
          },
          hideTitle: true
        }
      }
    };
  }

  getAccountList(page = this.INIT_PAGE, pageSize = this.PAGE_SIZE, filterName: string): Observable<GenericDict> {
    return this._accountService.getAccounts(page, pageSize, filterName).pipe(
      map(({ data }: Permission<Paginate<AccountList>>) => {
        const offset = this.accountsTableData.dataSource.data.length;
        if (!offset && data.total_record && this.accountsTableData.paginator) {
          this.accountsTableData.paginator.length = data.total_record;
        }
        return (data.records || []).map((elem: AccountList) => ({
          title: {
            value: elem.title
          },
          company_id: {
            value: elem.company_id || '-'
          },
          email: {
            value: elem.email
          },
          tier: {
            value: elem.tier
          },
          licenses: {
            value: elem.licenses
          },
          maker: {
            templateRef: this.makerTemplateRef,
            context: {
              makers: elem.makers
            }
          },
          expiration: {
            templateRef: this.expirationTemplate,
            context: {
              value: new Date(elem.expiration_time).toDateString().split(' ').slice(1).join(' '),
              status: elem.expiration_status
            }
          },
          actions: {
            inputs: {
              id: elem.id,
              type: 'Account',
              service: (i: number) => this._accountService.deleteAccount(i).pipe(tap(() => this.table.resetTable())),
              isAdmin$: this._store.pipe(
                select(StoreName.user),
                map(({ user_type }: UserState): boolean => user_type === UserType.admin)
              ),
              action: this.supportAccount.bind(this, elem.id, elem.email),
              confirmAccount: this.confirmAccount.bind(this, elem.email),
              sendEmail: this.sendEmail.bind(this, elem.email),
              resetPassword: this.resetPassword.bind(this, elem.email)
            }
          }
        }));
      })
    );
  }

  supportAccount(id: number, email: string): void {
    this._store.dispatch(AccountActions.set({ payload: { id, email } }));
  }

  confirmAccount(email: string) {
    this._dialog.open(ConfirmAccountComponent, {
      disableClose: true,
      data: { email }
    });
  }

  sendEmail(email: string): void {
    this._utilService.sendEmail(email);
  }

  resetPassword(email: string): void {
    this._utilService.resetPassword(email);
  }

  openMakerListDialog(makers: string[]): void {
    this._dialog.open(MakerListComponent, {
      width: '290px',
      data: makers
    });
  }

  ngOnDestroy(): void {
    this.destroySubject$.next();
    this.destroySubject$.complete();
  }
}

import { Injectable } from '@angular/core';
import { UsersService } from '../../services/users/users.service';
import { AlertsService } from '../../services/alerts/alerts.service';
import { AlertsType } from '@rappi/ui/alerts';
import { HttpErrorResponse } from '@angular/common/http';
import { BAD_REQUEST } from 'http-status-codes';
import { SupportService } from '../../services/support/support.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../shared/component-library/confirm-dialog/confirm-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor(
    private readonly _usersService: UsersService,
    private readonly _alertsService: AlertsService,
    private readonly _supportService: SupportService,
    private readonly _dialog: MatDialog,
  ) { }

  sendEmail(email: string): void {
    this._usersService.sendEmail(email).subscribe(
      ({ message }: { message: string }) => {
        this._alertsService.openAlerts(message, AlertsType.success);
      },
      ({ error: { message }, status }: HttpErrorResponse) => {
        this._alertsService.openAlerts(
          status === BAD_REQUEST ? 'This email has already been verified' : message,
          AlertsType.error
        );
      }
    );
  }

  resetPassword(email: string): void {
    this._supportService.resetPassword(email).subscribe(
      ({ data, message }) => {
        this._alertsService.openAlerts(message, AlertsType.success);
        this._dialog.open(ConfirmDialogComponent, {
          width: '576px',
          data: {
            textToShow: {
              titleWithoutTextBox: `New Password, please copy it and don't forget it`,
              content: `Your new password is: <strong>${data}</strong>`,
              confirmationText: 'Ok'
            }
          }
        });
      },
      (err: HttpErrorResponse) => this._alertsService.openAlerts(err.error.message, AlertsType.error)
    );
  }
}
